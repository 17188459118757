<template>
  <div class="mian">
    <div class="content">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb">
           <img src="../../assets/home/thumb1.png" alt="">
        </div>
        <div class="thumb-title">图书</div>
        <div class="sorting">排序显示</div>
        <div class="select">
          <el-select v-model="value" placeholder="请选择" @change="changeMethod">
            <el-option v-for="(item,index) in selectList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
    <!-- 内容 -->
      <div class="content-view">
        <div class="left">
          <el-tree class="is-current" :data="data" :highlight-current='true'
            :expand-on-click-node="false" @node-click="handleNodeClick"></el-tree>
        </div>
        <div class="book-list">
          <div class="list">
            <bookList v-for="(item,index) in List" :key="index" :index="index" :item="item"></bookList>
          </div>
          <div class="page" v-show="pageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bookList from '../home/components/newBook-box'
export default {
	components:{bookList},
	data() {
		return {
			pageShow:false,
			totals:1,
			currentPage:1,
			pageSize:16,
			selectList:[{
			  value: '',
			  label: '默认'
			},{
			  value: '1',
			  label: '按点击量'
			},{
			  value: '2',
			  label: '按收藏量'
			}],
			value: '',
			labelId:'',
			depth:'1',
			data: [],
			defaultProps: {
				children: 'children',
				label: 'label'
			},
			List:[],
			label:{label:'全部'}
		}
	},
	created(){
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.getLabel()
		this.getBooks()
		this.$store.commit('changClick',1)
		this.handleCurrentChange(1)
		this.currentMethod()
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
		line(){
			return this.$store.state.isZSkin
		},
	},
	methods:{
		/**获取图书分类**/ 
		getLabel(){
			var that = this
			that.$api.getLabel({}).then(res=>{
				if (res.data.code == 0) {
					var list1 = []
					var list2 = []
					var list3 = []
					list1.push({
						label:'全部',
						id:'',
						depth:'1',
						children:[]
					})
					res.data.data.forEach((item,index) => {
						list2 = []
						list1.push({
							label:item.labelName,
							id:item.id,
							depth:item.depth,
							children:list2
						})
						if (item.children != '') {
							item.children.forEach((item1,index1) => {
								list3 = []
								list2.push({
									label:item1.labelName,
									id:item1.id,
									depth:item1.depth,
									children:list3
								})
								if (item1.children != '') {
									item1.children.forEach((item2,index2) => {
										list3.push({
											label:item2.labelName,
											id:item2.id,
											depth:item2.depth,
										})
									})
								}
							})
						}
					})
					that.data = list1
					this.handleNodeClick(that.label,that.depth)
				}else{
					that.data = []
				}
			})
		},
		/**获取图书**/ 
		getBooks(){
			var that = this
			that.$api.getBooks({
				params:{
					labelId:that.labelId,
					pageNum:that.currentPage,
					pageSize:that.pageSize,
					sort:that.value,
					labeDepth:that.depth
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.List = res.data.data.list
					that.totals = res.data.data.pages
					if (res.data.data.list.length == 0){
						that.pageShow = false
					}else {
						that.pageShow = true
					}
				}else{
					that.List = []
					that.pageShow = false
				}
			})
		},
		/**下拉框选择回调**/
		changeMethod(index){
			this.value = index
			this.getBooks()
		},
		/**菜单选择按钮**/
		handleNodeClick(index){
			this.labelId = index.id
			this.depth = index.depth
			this.getBooks()
			/**更换菜单选择按钮皮肤**/
			this.$nextTick(function () {
				var array = document.querySelectorAll(".el-tree-node__content"); 
				if(array && array.length>0){
					array.forEach((item,index1) => {
						if (index.label == array[index1].innerText) {
						  array[index1].style.background  = this.isSkin;
						  array[index1].style.color  = "#FFF";
						}else{
						  array[index1].style.background  = "#FFF";
						  array[index1].style.color  = "#333";
						}
					})
				}
			});
		},
		/**当前页按钮**/ 
		handleCurrentChange(index) {
			console.log(index);
			this.currentPage = index;
			this.getBooks()
			this.$nextTick(function () {
			/**更换分页按钮皮肤**/
			var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
			if(array && array.length>0){
				array.forEach((item,index1) => {
					if (index == array[index1].innerText) {
						array[index1].style.background  = this.isSkin;
					}else{
						array[index1].style.background  = "#f4f4f5";
					}
				})
			}
			});
		},
		currentMethod(){
			this.$nextTick(function () {
				/**更换下拉选择框皮肤**/
				var array = document.querySelectorAll(".el-input__inner"); 
				if (array && array.length>0) {
					array[0].style.backgroundColor  = this.line;
					array[0].style.border  = this.line;
				}
				var array1 = document.querySelectorAll(".el-select .el-input.is-focus .el-input__inner"); 
				if (array1 && array1.length>0) {
					array1[0].style.borderColor  = this.line;
				}
				var array2 = document.querySelectorAll(".el-select .el-input__inner:focus"); 
				if (array2 && array2.length>0) {
					array2[0].style.borderColor  = this.line;
				}
			})
		}
	}
}
</script>

<style scoped lang="scss">
.mian{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content{
    width: 1200px;
    margin-top: 50px;
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      display: flex;
      justify-items: center;
      align-items: center; 
      position: relative;
      .thumb{
        margin-left: 15px;
        width: 15px;
        height: 15px;
        display: flex;
        justify-items: center;
        align-items: center; 
      }
      .thumb-title{
        font-size: 18px;
        line-height: 55px;
        color: #FFFFFF;
        margin-left: 10px;
        font-weight: 500;
      }
      .select{
        position: absolute;
        right: 12px;
      }
      .sorting{
        font-size: 13px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.9);
        position: absolute;
        right: 136px;
        font-weight: normal;
        cursor: pointer;
      }
    } 
    .content-view{
      width: 1200px;
      display: flex;
      justify-items: center;
      align-items: flex-start;
      .left{
        width: 285px;
        margin-top: 20px;
		margin-bottom: 20px;
        .is-current{
          background-color: #FFF 0px;
        }
      }
      .book-list{
        width: 895px;
        .list{
          width: 895px;
          display: flex;
          display: -webkit-flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 20px;
          padding-top: 20px;
        }
        .page{
          width: 896px;
          padding: 20px 20px 100px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .page1{
            display: flex;
            justify-items: center;
            align-items: center;
          }
        }
      }
    }
  }
}

</style>